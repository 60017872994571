.toaster-error {
  background: $primaryRed !important;
  color: #fff !important;
  span {
    color: #fff;
  }

  strong {
    margin-left: 0.5rem;
  }

  button {
    margin: 0 !important;
  }
}

.toaster-success {
  background: $blue !important;
  color: #fff !important;
  span {
    color: #fff;
  }

  strong {
    margin-left: 0.5rem;
  }

  button {
    margin: 0 !important;
  }
}

.toast {
  max-width: 95% !important;
}
