.modal-content {
  background: $lightGrey !important;
  height: 100vh;
  justify-content: center;
  border: none !important;
  .modal-container {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
  }

  .details-container {
    border: none;
  }
  h4 {
    font-weight: bold;
  }

  p {
    margin-bottom: 1rem;
  }

  textarea {
    @include box-shadow;
    border-radius: 3px;
    border: none;
    padding: 0.5rem;
    &:focus {
      outline: none;
    }
  }

  input {
    border: none;
    border-bottom: 1px solid #dcdcdc;
    padding: 0.5rem;
    &:focus {
      outline: none;
    }
  }
  ::-webkit-input-placeholder {
    color: #c8c8c8;
  }
  ::-moz-placeholder {
    color: #c8c8c8;
  }
  :-ms-input-placeholder {
    color: #c8c8c8;
  }
  :-moz-placeholder {
    color: #c8c8c8;
  }

  .star-ratings {
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 0.5rem;
    margin-bottom: 3rem;
  }

  .modal-body {
    padding: 1rem;
  }
  .modal-header {
    border: 0;
    button {
      margin: -1rem auto -1rem -1rem;
    }
  }

  .button-container {
    margin: 3rem 0 1rem 0;
  }

  .form-control {
    &:focus {
      outline: none;
      box-shadow: none;
      border-color: rgba(0, 0, 0, 0.2);
    }
  }

  .input-group-append {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    svg {
      align-self: center;
    }
  }
}
.modal-lg {
  margin: 0 !important;
}

@media only screen and (min-width: 340px) {
  .modal-content {
    textarea {
      height: 10rem;
    }
  }
}