.form-container {
  padding: 1rem 0;
  border-top: 1px solid #adadad;
  border-bottom: 1px solid #adadad;
  margin: 0.5rem, 0, 1.5rem 0;
  color: $primaryGrey;

  input {
    background: #fff;
    border-radius: 3px;
    margin-bottom: 1.5rem;
  }

  .form-check {
    margin-bottom: 1rem;
  }

  .form-control-plaintext {
    @include box-shadow;
    padding: 0.5rem;
  }

  .form-check-input {
    margin-right: 0.2rem;
  }
}

.preference-col {
  @include remove-padding;
  flex-direction: column;

  .button-container {
    margin-top: 1rem;
  }
}
