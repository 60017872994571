@mixin bubble-left {
  border-radius: 0 20px 20px 20px;
  padding: 8px 15px;
  display: inline-block;
  background: #fff;
}

@mixin bubble-right {
  border-radius: 20px 0 20px 20px;
  padding: 8px 15px;
  display: inline-block;
  background: #fff;
}

.bot-wrapper {
  height: 100vh;
}

.bot-header {
  background: $primaryRed;
  text-align: center;
  color: #fff;
  height: 50px;
  line-height: 50px;
  margin: 0;
}

.bot .message-bot,
.message-user,
.message-bot-reset {
  margin-right: 5%;
  margin-left: 5%;
  background-color: #e5e5e5;
  position: relative;
}

.bot-container {
  display: flex;
  flex-direction: column;
  max-width: 420px;
  margin: 0 auto;
  overflow-y: auto;
  height: calc(100% - 120px);

  .messages {
    display: flex;
    flex-direction: row;
    padding: 8px 15px;
    -moz-animation: fadein 2s;
    -ms-animation: fadein 2s;
    -o-animation: fadein 2s;
    animation: fadein 2s;
  }

  .message-body {
    width: calc(100% - 40px);
    display: flex;
    flex: 1;
    flex-direction: column;
    strong {
      margin: 0 5%;
      font-weight: bold;
      color: $primaryGrey;
      font-size: 14px;
    }
  }

  .message-bot {
    @include bubble-left();
    color: $primaryGrey;
  }

  .message-bot-reset {
    border-radius: 0 20px 0 0;
    padding: 8px 15px;
    display: inline-block;
    background: #fff;
  }

  .reset-button {
    background: $lightGrey;
    border: 1px solid $primaryGrey;
    font-weight: 400;
    border-radius: 0 0 20px 20px;
    color: $blue;
    text-align: center;
    padding: 8px 15px;
    margin: 0 5%;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .user-image {
    display: flex;
    justify-content: center;
    padding-top: 9px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: $primaryRed;
  }
}

.bot-footer {
  display: flex;
  margin: 0 auto;
  max-width: 420px;
  height: 70px;
  padding: 15px;
  justify-content: center;
  .message-user {
    width: 100%;
  }

  .footer-icon {
    display: flex;
    flex: 1;
    align-items: center;
  }
  button {
    width: 90%;
  }
}

.message-user {
  @include bubble-right();
  border: 1px solid $primaryGrey;
  input {
    border: none;
    width: 100%;
  }

  input:focus {
    outline: none;
  }
}

.message-user:focus {
  outline: none;
}
