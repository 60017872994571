.button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10rem;

  .accept-button {
    @include box-shadow;
    svg {
      margin-right: 0.7rem;
    }
    background: $blue;
    border-color: $blue;
    &:hover,
    &:active {
      background: $blue !important;
      color: #fff !important;
      border: 1px solid $blue;
      border-color: $blue !important;
      svg {
        color: #fff;
      }
    }
    &:focus {
      box-shadow: none !important;
    }
    margin: 0.5rem 0;
  }

  .interested-button {
    @include box-shadow;
    svg {
      margin-right: 0.7rem;
    }
    background: $background;
    border-color: $blue;
    color: $primaryGrey;
    &:hover,
    &:active {
      background: $blue !important;
      color: #fff !important;
      border: 1px solid $blue;
      border-color: $blue !important;
      svg {
        color: #fff;
      }
    }
    &:focus {
      box-shadow: none !important;
    }
    margin: 0.5rem 0;
  }

  .mail-button {
    @include box-shadow;
    svg {
      margin-right: 0.7rem;
    }
    background: $primaryGrey;
    border-color: $primaryGrey;
    &:hover,
    &:active {
      background: $blue !important;
      color: #fff !important;
      border: 1px solid $blue;
      border-color: $blue !important;
      svg {
        color: #fff;
      }
    }
    &:focus {
      box-shadow: none !important;
    }
    margin: 0.5rem 0;
  }

  .red {
    background: $primaryRed;
  }

  span.spinner-border {
    margin-right: 10px;
  }

  .clipboard-button,
  .clipboard-button:hover,
  .clipboard-button:active,
  .clipboard-button:focus {
    font-weight: bold;
    display: flex !important;
    justify-content: space-between;
    background: #f6f6f6 !important;
    border: none !important;
    outline: none;
    color: $primaryGrey !important;
    div {
      color: #d4d4d4;
    }
    margin: 0.5rem 0;
    box-shadow: none !important;
  }
}

.review-padding {
  padding-top: 1.5rem;
}
