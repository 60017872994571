.details-container {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0;
  border-top: 1px solid $primaryGrey;
  border-bottom: 1px solid $primaryGrey;
  .details {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    text-align: left;
    svg {
      width: 20px;
      margin-right: 1.2rem;
    }
    p {
      margin: 0;
    }
  }
}
