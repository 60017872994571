.card-container {
  @include box-shadow;
  color: $primaryGrey;
  background: #fff;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-radius: 3px;
  margin: 0.75rem 0;
  cursor: pointer;
  text-decoration: none;
  width: 98%;
  p {
    padding:0 !important;
  }
  h6 {
    margin-top: 0.5rem;
    color: $primaryGreyHover;
    font-weight: bolder;
  }
  .card-inner-container {
    display: flex;
    .flex-column {
      margin: 0 0.5rem;
      width: 100%;
    }
  }
  .category-location {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .location {
    color: $primaryGreyHover;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;

    p {
      font-weight: bolder;
    }

    svg {
      margin-left: 0.25em;
    }
  }
  p {
    overflow: hidden;
    white-space: nowrap;
    padding: 0 0.25rem;
    text-overflow: ellipsis;
    margin: 0;
    font-size: 14px;
  }
  svg {
    margin-right: 0.25rem;
  }

  padding: 1rem;

  svg {
    color: $primaryRed;
  }

  .event-icon {
    padding: 0.5rem;
    background: $primaryRed;
    color: #fff;
    height: 2rem;
    width: 2rem;
    border-radius: 3px;
  }

  .interested {
    background: $primaryGrey;
  }

  .comming {
    background: $blue;
  }

  .interested-icon {
    color: $primaryGrey;
  }

  .comming-icon {
    color: $blue;
  }
}