.footer-navigation {
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  justify-content: space-evenly;
  border-top: 1px solid $primaryGrey;
  bottom: 0;
  background: #e5e5e5;

  .icon-holder {
    height: 1.75rem;
    width: 1.75rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin-top: 0.4rem;
  }

  svg {
    display: flex;
    margin: auto;
    color: $primaryGrey;
  }

  a,
  div {
    @include flexCenter;
    width: 100%;
    color: $primaryGrey;
    cursor: pointer;
    padding: 5px 0;
  }

  p {
    text-align: center;
    color: $primaryGrey;
    font-size: 16px;
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
  }
}

.active-link {
  .icon-holder {
    background: $blue;
    color: #fff;
    border-radius: 1.75rem;
    svg {
      color: #fff;
    }
  }

  p {
    color: $blue;
    margin: 0;
  }
}
