@mixin flexCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin remove-padding {
  padding: 0;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin box-shadow {
  -webkit-box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.14);
  box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.14);
}

html,
body {
  display: flex;
  flex: 1;
  margin: 0;
  font-family: "Ubuntu", sans-serif !important;
}

#root {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: #e5e5e5;
  flex: 1;
}

p {
  margin: 0;
}

a {
  text-decoration: none !important; /* no underline */
  &:hover {
    text-decoration: none;
  }
}

.containment {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 !important;
}

.wrapper {
  flex: 1;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 15px;
  h6 {
    margin: 1rem 0;
  }
}

.header {
  padding-top: 1.5rem;
  p {
    color: $primaryGrey;
    padding-bottom: 1rem;
  }
}

.header-custom-margin {
  padding-top: 5rem;
}

.nopadding {
  padding: 0;
}

.skeleton {
  margin: 0.75rem 0;
}

.shadow-down {
  z-index: 10;
  position: absolute;
  height: 2rem;
  top: 4.5rem;
  left: 0;
  width: 100%;
  background: rgb(229, 229, 229);
  background: linear-gradient(
    180deg,
    rgba(229, 229, 229, 1) 0%,
    rgba(229, 229, 229, 0) 100%
  );
}

.shadow-down-myevents {
  z-index: 10;
  position: absolute;
  height: 2rem;
  top: 7.5rem;
  left: 0;
  width: 100%;
  background: rgb(229, 229, 229);
  background: linear-gradient(
    180deg,
    rgba(229, 229, 229, 1) 0%,
    rgba(229, 229, 229, 0) 100%
  );
}

.shadow-up {
  z-index: 10;
  position: absolute;
  height: 2rem;
  bottom: 4rem;
  left: 0;
  width: 100%;
  background: rgb(229, 229, 229);
  background: linear-gradient(
    0deg,
    rgba(229, 229, 229, 1) 0%,
    rgba(229, 229, 229, 0) 100%
  );
}

.error-page {
  margin-bottom: 5rem;
  h1 {
    font-size: 25px;
  }
}

.no-margin {
  margin: 0 !important;
}
