.preference {
  margin-top: 1rem;
  textarea {
    width: 100%;
    height: 10rem;
  }

  p {
    font-size: 16px;
    margin-bottom: 0;
  }

  .preference-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin: 1rem 0;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 1.5rem;
  }

  .preference-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
  }

  .preference-container:hover input ~ .checkmark {
    background-color: #fff;
  }

  .preference-container input:checked ~ .checkmark {
    background-color: $blue;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .preference-container input:checked ~ .checkmark:after {
    display: block;
  }

  .preference-container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
